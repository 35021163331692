<template>
  <div>
    <!-- Heading -->
    <div class="beam-container">
      <!-- Pill -->
      <div
        class="inline-flex items-center rounded bg-green-600/10 px-3 py-2 text-xs font-medium uppercase text-green-600 sm:text-base"
      >
        <!-- Dot -->
        <div
          class="mr-2.5 h-2.5 w-2.5 animate-pulse rounded-full bg-green-600"
        ></div>

        Live Impact Data
      </div>

      <h1
        class="beam-h1 mt-8 flex flex-col text-left lg:flex-row lg:items-center"
      >
        <div class="mr-6">Beam has supported</div>
        <div class="mt-1 flex items-center lg:mt-0">
          <number-counter-group
            class="beam-h1"
            :increment-amount="3"
            :number="numberPeopleSupported"
          ></number-counter-group>
          <div class="ml-5">people</div>
        </div>
      </h1>
      <h1
        class="beam-h1 mt-8 flex flex-col text-left lg:flex-row lg:items-center"
      >
        <div class="mr-6">Saving the taxpayer</div>
        <div class="mt-1 flex items-center lg:mt-0">
          <number-counter-group
            class="beam-h1"
            :increment-amount="90003"
            :number="taxpayerSavings.total"
            :is-currency="true"
          ></number-counter-group>
        </div>
      </h1>

      <hr class="mb-5 mt-8 h-px bg-pale-lilac-100 sm:mb-10 sm:mt-20" />
    </div>

    <!-- Tab links -->
    <div
      class="beam-container flex flex-col items-start space-y-4 lg:flex-row lg:items-center lg:justify-around lg:space-y-0"
    >
      <button
        v-scroll-to="{ el: '#social-impact', offset: -120 }"
        class="text-lg font-medium underline"
      >
        Social impact
      </button>
      <button
        v-scroll-to="{ el: '#taxpayer-savings', offset: -120 }"
        class="text-lg font-medium underline"
      >
        Taxpayer savings
      </button>

      <button
        v-scroll-to="{ el: '#who-helped', offset: -120 }"
        class="text-lg font-medium underline"
      >
        Who we've helped
      </button>
      <button
        v-scroll-to="{ el: '#service-ratings', offset: -120 }"
        class="text-lg font-medium underline"
      >
        Service ratings
      </button>
      <button
        v-scroll-to="{ el: '#community', offset: -120 }"
        class="text-lg font-medium underline"
      >
        Community
      </button>
    </div>

    <!-- Grey background from here -->
    <div class="mt-5 bg-pale-grey-100 pt-10 sm:mt-8 sm:pt-20">
      <div class="beam-container">
        <!-- Pill -->
        <div
          id="social-impact"
          class="inline-block rounded bg-white px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          Social impact
        </div>

        <h1 class="beam-h1 mt-3 items-center text-left sm:mt-5 sm:flex">
          <number-counter-group
            class="beam-h1"
            counter-background-color="bg-white"
            :number="numberPeopleSupported"
          ></number-counter-group>
          <div class="mt-3 sm:ml-5 sm:mt-0">people supported through Beam</div>
        </h1>

        <div class="mt-6 bg-white p-4 lg:h-[547px]">
          <bar-chart
            url="/charts/lives_changed_charts"
            :show-tabs="false"
            :display-legend="true"
            initial-selected-group="yearly"
            x-axis-label="Total people helped with Beam (cumulative)"
            :colours="['#0AADFF', '#FFD334', '#FF3066']"
            chart-type="stacked-bar"
          >
          </bar-chart>
        </div>

        <h1 class="beam-h1 mt-10 items-center text-left sm:mt-20 sm:flex">
          <number-counter-group
            class="beam-h1"
            counter-background-color="bg-white"
            :number="numberJobsOutcomes"
          ></number-counter-group>
          <div class="mt-3 sm:ml-5 sm:mt-0">people supported into jobs</div>
        </h1>

        <!-- Careers -->
        <h1 class="beam-h1 mt-10 flex items-center text-left sm:mt-20">
          <number-counter-group
            class="beam-h1"
            counter-background-color="bg-white"
            :number="numberOfCareers"
          ></number-counter-group>
          <div class="ml-2 sm:ml-5">different careers</div>
        </h1>

        <div
          class="mt-5 items-center space-y-3 lg:flex lg:space-x-5 lg:space-y-0"
        >
          <small-success-box photo="/images/hana-pink-small.jpg"
            >Hana worked with Beam to become a
            <span class="font-medium">beauty therapist</span> in February 2018
          </small-success-box>
          <small-success-box photo="/images/regina-yellow.png">
            Regina worked with Beam to become a
            <span class="font-medium">dental nurse</span> in October 2018
          </small-success-box>
          <small-success-box photo="/images/john-gardener-blue.png">
            John worked with Beam to become a
            <span class="font-medium">gardener</span> in November 2018
          </small-success-box>
        </div>

        <div class="mt-5 font-medium">
          <a
            target="_blank"
            class="font-medium underline"
            href="/career-pathways"
          >
            Full list of careers people found with Beam
          </a>
        </div>

        <h1 class="beam-h1 mt-10 items-center text-left sm:mt-20 sm:flex">
          <number-counter-group
            class="beam-h1"
            counter-background-color="bg-white"
            :number="numberHomesOutcomes"
          ></number-counter-group>
          <div class="mt-3 sm:ml-5 sm:mt-0">people supported into housing</div>
        </h1>

        <!-- White boxes -->
        <div
          class="mt-4 md:mt-8 items-center space-y-3 lg:mt-5 lg:flex lg:space-x-5 lg:space-y-0"
        >
          <small-success-box photo="/images/john-blue.png">
            John worked with Beam to find housing in
            <span class="font-medium">Brent</span> in June 2022
          </small-success-box>
          <small-success-box photo="/images/kateryna-pink.png"
            >Kateryna worked with Beam to find housing in
            <span class="font-medium">Enfield</span> in October 2022
          </small-success-box>
          <small-success-box photo="/images/agnes-yellow.png">
            Agnes worked with Beam to find housing in
            <span class="font-medium">Greenwich</span> in March 2021
          </small-success-box>
        </div>

        <h1 class="beam-h1 mt-10 items-center text-left sm:mt-20 sm:flex">
          <number-counter-group
            class="beam-h1"
            counter-background-color="bg-white"
            :number="preventedHomelessnessCount"
          ></number-counter-group>
          <div class="mt-3 sm:ml-5 sm:mt-0">
            people prevented from homelessness
          </div>
        </h1>

        <hr class="mb-10 mt-8 h-px bg-pale-lilac-100 sm:mb-20 sm:mt-10" />

        <!-- Pill -->
        <div
          id="taxpayer-savings"
          class="inline-block rounded bg-white px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          Taxpayer savings
        </div>

        <h1 class="beam-h1 mt-8 text-left">
          For every £1 the government spends on Beam,
          <br />
          <span class="yellow-underline-h1"
            >£7 is returned to the taxpayer.</span
          >
        </h1>

        <div
          class="mt-10 bg-white px-5 pb-5 pt-5 text-center lg:px-10 lg:pb-10 lg:pt-8"
        >
          <div class="text-lg font-medium">Estimated savings by department</div>
          <div class="mb-10 mt-4 lg:mt-8">
            <div class="grid grid-cols-2 gap-0.5 lg:grid-cols-8">
              <!-- Central Govt -->
              <div
                class="flex items-center justify-center bg-teal-500/10 lg:order-5 lg:col-span-3"
              >
                <div class="text-lg font-medium">Central Government</div>
              </div>
              <div class="bg-teal-500 py-32 lg:order-1 lg:col-span-3 lg:py-10">
                <div class="text-lg font-medium">
                  {{ taxpayerSavings.central_formatted }}
                </div>
              </div>

              <!-- Local Govt -->
              <div
                class="bg-yellow-100/10 py-32 lg:order-6 lg:col-span-3 lg:py-10"
              >
                <div class="text-lg font-medium">Local Government</div>
              </div>
              <div
                class="bg-yellow-100 py-32 lg:order-2 lg:col-span-3 lg:py-10"
              >
                <div class="text-lg font-medium">
                  {{ taxpayerSavings.local_formatted }}
                </div>
              </div>

              <!-- MOJ -->
              <div
                class="col-span-1 flex items-center justify-center bg-gray-300/10 lg:order-7"
              >
                <img :src="`/images/moj.png`" alt="MOJ" style="height: 60px" />
              </div>
              <div class="col-span-1 bg-gray-300 py-8 lg:order-3 lg:py-10">
                <div class="text-lg font-medium">
                  {{ taxpayerSavings.moj_formatted }}
                </div>
              </div>

              <!-- NHS -->
              <div
                class="col-span-1 flex items-center justify-center bg-sky-600/10 lg:order-8"
              >
                <img :src="`/images/nhs.png`" alt="NHS" class="h-8" />
              </div>
              <div class="col-span-1 bg-sky-600 py-8 lg:order-4 lg:py-10">
                <div class="text-lg font-medium text-white">
                  {{ taxpayerSavings.nhs_formatted }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="beam-h2 mt-8 text-left sm:mt-16">Total taxpayer savings</h2>
        <number-counter-group
          class="beam-h1 mt-3 sm:mt-5"
          counter-background-color="bg-white"
          :number="taxpayerSavings.total"
          :is-currency="true"
        ></number-counter-group>

        <hr class="mb-10 mt-8 h-px bg-pale-lilac-100 sm:mb-20 sm:mt-10" />

        <!-- Pill -->
        <div
          id="who-helped"
          class="inline-block rounded bg-white px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          Who We've helped
        </div>

        <h1 class="beam-h1 mt-8 text-left">
          Supporting people from
          <span class="yellow-underline-h1">every walk of life</span>
        </h1>

        <!-- White boxes -->
        <div
          class="mt-8 items-center space-y-3 sm:mt-5 sm:flex sm:space-x-8 sm:space-y-0"
        >
          <div class="w-full bg-white py-8 text-center sm:py-12">
            <div class="heading-level-1 font-medium">
              {{ roughSleepersCount.toLocaleString() }}
            </div>
            <div class="mt-2.5 text-lg font-medium">
              people who
              <br class="hidden sm:block" />
              slept rough
            </div>
          </div>
          <div class="w-full bg-white py-8 text-center sm:py-12">
            <div class="heading-level-1 font-medium">
              {{ criminalRecordsCount.toLocaleString() }}
            </div>
            <div class="mt-2.5 text-lg font-medium">
              people who have
              <br class="hidden sm:block" />
              criminal records
            </div>
          </div>
          <div class="w-full bg-white py-8 text-center sm:py-12">
            <div class="heading-level-1 font-medium">
              {{ refugeesCount.toLocaleString() }}
            </div>
            <div class="mt-2.5 text-lg font-medium">
              people who
              <br class="hidden sm:block" />
              are refugees
            </div>
          </div>
        </div>

        <h2 class="beam-h2 mt-10 text-left sm:mt-16">
          54% of people using Beam identify as female or non-binary
        </h2>

        <!-- Gender table -->
        <tally-table
          class="mt-5"
          title="Gender of Beam users"
          :tally-data="genders"
        ></tally-table>

        <h2 class="beam-h2 mt-10 text-left sm:mt-16">
          65% of people using Beam identify as ethnically diverse
        </h2>

        <tally-table
          class="mt-5"
          title="Ethnicity of Beam users"
          :tally-data="ethnicities"
        ></tally-table>

        <h2 class="beam-h2 mt-10 text-left sm:mt-16">
          {{ withdrawnPercent }}% of people have withdrawn from Beam
        </h2>

        <hr class="mb-10 mt-8 h-px bg-pale-lilac-100 sm:mt-20" />

        <div
          id="service-ratings"
          class="inline-block rounded bg-white px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          Service Ratings
        </div>

        <h1 class="beam-h1 mt-8 text-left">
          Rated
          <span class="yellow-underline-h1">excellent by beneficiaries</span>
        </h1>

        <div class="mt-5 bg-white py-10 text-center">
          <img
            :src="`/images/stars.png`"
            class="inline w-64 sm:w-auto sm:max-w-xl"
          />
          <div class="heading-level-1 mb-1 mt-5 font-medium">9.6 / 10</div>
          average from 1,011 Beam beneficiaries
        </div>

        <div
          class="mt-3 items-center space-x-0 space-y-3 sm:mt-5 sm:flex sm:space-x-5 sm:space-y-0"
        >
          <rating-quote
            quote="Beam helped me break the cycle of dependency. Because of your generosity, I'm living a full life today. With qualifications under my belt, I was able to get a job, sort out permanent housing and get off benefits."
          >
            <template #photo>
              <img
                :src="`/images/tony-yellow.png`"
                class="block h-20 w-20 rounded-full"
              />
            </template>

            <template #quoter>
              <div class="mt-1.5 italic">Tony, Beam beneficiary</div>
            </template>
          </rating-quote>
          <rating-quote
            quote="I wanted to say a big thank you to Beam and all my supporters. You guys are always there for me. Thank you for your kind words and having faith in me. I can’t even describe how happy I am."
          >
            <template #photo>
              <img
                :src="`/images/marzena-blue.png`"
                class="block h-20 w-20 rounded-full"
              />
            </template>

            <template #quoter>
              <div class="mt-1.5 italic">Marzena, Beam beneficiary</div>
            </template>
          </rating-quote>
        </div>

        <h1 class="beam-h1 mt-10 text-left sm:mt-16">
          Rated
          <span class="yellow-underline-h1">excellent by referrers</span>
        </h1>

        <div class="mt-5 bg-white py-10 text-center">
          <img
            :src="`/images/stars.png`"
            class="inline w-64 sm:w-auto sm:max-w-xl"
          />
          <div class="heading-level-1 mb-1 mt-5 font-medium">9.5 / 10</div>
          average from 107 Beam referrers
        </div>

        <div
          class="mt-3 items-center space-x-0 space-y-3 sm:mt-5 sm:flex sm:items-stretch sm:space-x-5 sm:space-y-0"
        >
          <rating-quote
            quote="Beam is a groundbreaking platform. Our partnership with Beam gives local people the opportunity to directly help those in most need."
          >
            <template #photo>
              <img
                :src="`/images/hammersmith-and-fulham.png`"
                class="block h-16 sm:h-20"
              />
            </template>

            <template #quoter>
              <div class="mt-1.5 italic">
                Cllr Lisa Homan, H&F Cabinet Member for Housing
              </div>
            </template>
          </rating-quote>
          <rating-quote
            quote="Beam has been fantastic to work with. We have already had a number of successes and look forward to helping prevent homelessness and improve more people's lives."
          >
            <template #photo>
              <img :src="`/images/leicester.png`" class="block h-16 sm:h-20" />
            </template>

            <template #quoter>
              <div class="mt-1.5 italic">
                Charlotte McGraw, Head of Housing Service, Leicester City
                Council
              </div>
            </template>
          </rating-quote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberPeopleSupported: {
      type: Number,
    },
    numberJobsOutcomes: {
      type: Number,
    },
    taxpayerSavings: {
      type: Object,
    },
    numberOfCareers: {
      type: Number,
    },
    numberHomesOutcomes: {
      type: Number,
    },
    preventedHomelessnessCount: {
      type: Number,
    },
    genders: {
      type: Object,
    },
    ethnicities: {
      type: Object,
    },
    withdrawnPercent: {
      type: Number,
    },
    numberMessages: {
      type: Number,
    },
    budgetItemCounts: {
      type: Object,
    },
    refugeesCount: {
      type: Number,
    },
    roughSleepersCount: {
      type: Number,
    },
    criminalRecordsCount: {
      type: Number,
    },
    supporterMessages: { type: Array },
    supportersCount: { type: Number },
    totalDonated: { type: String },
  },
}
</script>

<template>
  <div>
    <header>
      <h1 class="beam-h1">Refer someone to Beam</h1>
    </header>

    <main class="mb-16">
      <section class="mx-auto mt-16 max-w-3xl">
        <div class="beam-header-intro">
          Referring someone to Beam takes 2 minutes. They'll be assigned a Beam
          caseworker who'll contact them quickly and keep you updated with
          progress. Share the load, change lives.
        </div>
      </section>

      <!-- Form -->
      <section class="mx-auto mt-16 max-w-lg">
        <form method="post" action="/refer">
          <div>
            <label for="email_address" class="inline-block font-medium"
              >Make a referral by entering your work email...</label
            >
            <input
              type="email"
              name="email_address"
              id="email_address"
              placeholder="Enter your email"
              required="required"
              :value="initialEmail"
              class="beam-text-input mt-5 w-full"
            />
          </div>

          <div class="text-center">
            <input
              type="submit"
              value="Make a referral"
              class="button button-primary mt-6 px-8 py-2"
            />
          </div>
          <input type="hidden" name="authenticity_token" :value="csrfToken" />
          <input type="hidden" name="contract_id" :value="contractId" />
        </form>
      </section>

      <!-- Nick -->
      <section class="mx-auto mt-16 max-w-3xl lg:mt-20">
        <!-- Mobile -->
        <div class="mx-auto sm:w-128 lg:hidden">
          <div class="text-center text-pink-100">
            <quote-icon class="inline-block h-4 w-5"></quote-icon>
          </div>
          <div class="mx-auto mt-3 text-center font-medium">
            I've been clean nearly a year. I'm healthy, and I've got my
            relationship back with my family.
          </div>
        </div>

        <div class="mt-16 items-center justify-start sm:flex">
          <!-- Campaign Card -->
          <div
            class="mx-auto max-w-card shrink-0 sm:w-64 sm:max-w-full lg:mr-16"
          >
            <a href="/stories">
              <img
                :src="nickCampaign.photo"
                :alt="nickCampaign.name"
                class="w-full"
              />
            </a>
          </div>

          <!-- Desktop Quote -->
          <div class="hidden lg:block">
            <div class="text-pink-100">
              <quote-icon class="inline-block h-4 w-5"></quote-icon>
            </div>
            <div class="mt-5 text-left text-lg font-medium">
              I've been clean nearly a year, I'm healthy, and I've got my
              relationship back with my family.
            </div>
          </div>
        </div>
      </section>

      <section class="mt-16">
        <div
          class="full-screen-mobile stack-mt-6 flex flex-col items-center rounded bg-pale-grey-100 p-8 sm:flex-row sm:p-12"
        >
          <div class="rounded bg-white p-8 text-center sm:mr-8 sm:mt-0">
            If you're a charity or local authority that wishes to partner with
            Beam and make referrals, please email
            <a href="mailto:partner@beam.org" class="blue-link"
              >partner@beam.org</a
            >.
          </div>

          <div class="rounded bg-white p-8 text-center sm:ml-8 sm:mt-0">
            Any issues or questions about the form or our eligibility criteria,
            please email
            <a href="mailto:referrals@beam.org" class="blue-link"
              >referrals@beam.org</a
            >.
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    csrfToken: { type: String },
    initialEmail: { type: String, required: false },
    nickCampaign: { type: Object, required: true },
    contractId: { type: String, required: false },
  },
}
</script>

<template>
  <div>
    <header class="mt-8">
      <h1 class="beam-h1">Community news</h1>

      <div class="beam-header-intro">
        Thanks to you, we’ve helped
        <a href="/campaigns/funded" class="blue-link"
          >{{ livesChangedCount }} people</a
        >
        find new jobs and homes. Check out the latest updates from recent
        campaigns.
      </div>
    </header>

    <main class="my-10 min-h-screen sm:my-20 lg:flex">
      <!-- Width is here to prevent flicker on page load -->
      <div class="lg:w-screen">
        <!-- To prevent flicker -->
        <div class="min-h-screen bg-pale-grey-100">
          <updates-list
            :single-id="singleId"
            :first-ids="firstIds"
            :notices="notices"
          >
          </updates-list>
        </div>
      </div>

      <aside class="sticky top-32 ml-16 hidden h-full w-80 shrink-0 lg:block">
        <div class="rounded bg-pale-grey-100 p-8">
          <div class="heading-level-4 font-medium">Live campaigns</div>

          <campaign-card-small
            v-for="campaign in liveCampaigns"
            :key="campaign.id"
            :member-name="campaign.member_name"
            :stage-display="campaign.sentence_stage_display"
            :slug="campaign.slug"
            :avatar-url="campaign.tiny_avatar_url"
          >
          </campaign-card-small>

          <div class="mt-8 text-center">
            <fancy-link
              href="/campaigns"
              class="button button-secondary inline-block w-full py-3"
              >View all live campaigns</fancy-link
            >
          </div>
        </div>

        <div class="mt-4 text-sm">
          © Beam Up Ltd {{ new Date().getFullYear() }}
        </div>
        <ul class="mt-4 flex">
          <li>
            <fancy-link href="/contact" class="blue-link mr-4 text-sm"
              >Contact</fancy-link
            >
          </li>
          <li>
            <fancy-link href="/careers" class="blue-link mr-4 text-sm"
              >Jobs</fancy-link
            >
          </li>
          <li><a href="/privacy" class="blue-link mr-4 text-sm">Privacy</a></li>
          <li><a href="/terms" class="blue-link mr-4 text-sm">Terms</a></li>
        </ul>
      </aside>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    livesChangedCount: { type: Number },
    liveCampaigns: { type: Array },
    singleId: { type: Number },
    firstIds: { type: Array },
    notices: { type: Array },
  },
  created() {
    this.$root.showFooter = false
  },
}
</script>
